import { User } from "../../models/users";

export const UserPhoneBodyTemplate = (user: User) => {
  if (!user.userPhoneCode || !user.userPhone) {
    return <div>No disponible</div>;
  }

  return (
    <div>
      <b>({user.userPhoneCode})</b> {user.userPhone}
    </div>
  );
};
