import React, { useState } from "react";
import {
  useDeleteFictionalUserMutation,
  useGetUsersQuery,
} from "../../store/services/users";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {
  ActionButtonTemplate,
  DateBodyTemplate,
  UserPhoneBodyTemplate,
} from "../../components";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Dialog } from "primereact/dialog";
import { User } from "../../models/users";

export const Users = () => {
  const [userEmail, setUserEmail] = useState<string>();
  const [userFirstName, setUserFirstName] = useState<string>();
  const [userLastName, setUserLastName] = useState<string>();
  const [fakeUserEmail, setFakeUserEmail] = useState<string>();
  const [fakeUserFirstName, setFakeUserFirstName] = useState<string>();
  const [fakeUserLastName, setFakeUserLastName] = useState<string>();
  const [userToDelete, setUserToDelete] = useState<User>();
  const navigate = useNavigate();
  const {
    data: usersData,
    isLoading,
    refetch,
  } = useGetUsersQuery({
    email: userEmail,
    firstName: userFirstName,
    lastName: userLastName,
    userTypeId: 2,
  });

  const {
    data: fakeUsersData,
    isLoading: isLoadingFakeUsers,
    refetch: refetchFakeUsers,
  } = useGetUsersQuery({
    email: fakeUserEmail,
    firstName: fakeUserFirstName,
    lastName: fakeUserLastName,
    userTypeId: 3,
  });
  const [deleteFictionalUser, { isLoading: isLoadingDelete }] =
    useDeleteFictionalUserMutation();

  const hideDeleteEventDialog = () => setUserToDelete(undefined);

  const handleDeleteUser = (id: number) => {
    deleteFictionalUser(id)
      .unwrap()
      .then(() => {
        toast.success("usuario eliminado exitosamente!");
        refetchFakeUsers();
      })
      .catch(() => toast.error("No se pudo eliminar el usuario"))
      .finally(() => hideDeleteEventDialog());
  };

  const RealUsersheader = (
    <div className="grid mt-4">
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="email"
            name="email"
            onChange={(e) => setUserEmail(e.currentTarget.value)}
            value={userEmail}
          />
          <label htmlFor="email">Email</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setUserEmail("");
            refetch();
          }}
        />
      </div>
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="lastName"
            name="lastName"
            onChange={(e) => setUserLastName(e.currentTarget.value)}
            value={userLastName}
          />
          <label htmlFor="lastName">Apellido</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setUserLastName("");
            refetch();
          }}
        />
      </div>
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="firstName"
            name="firstName"
            onChange={(e) => setUserFirstName(e.currentTarget.value)}
            value={userFirstName}
          />
          <label htmlFor="firstName">Nombre</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setUserFirstName("");
            refetch();
          }}
        />
      </div>
    </div>
  );

  const fakeUsersHeader = (
    <div className="grid mt-4">
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="email"
            name="email"
            onChange={(e) => setFakeUserEmail(e.currentTarget.value)}
            value={fakeUserEmail}
          />
          <label htmlFor="email">Email</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setFakeUserEmail("");
            refetchFakeUsers();
          }}
        />
      </div>
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="lastName"
            name="lastName"
            onChange={(e) => setFakeUserLastName(e.currentTarget.value)}
            value={fakeUserLastName}
          />
          <label htmlFor="lastName">Apellido</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setFakeUserLastName("");
            refetchFakeUsers();
          }}
        />
      </div>
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="firstName"
            name="firstName"
            onChange={(e) => setFakeUserFirstName(e.currentTarget.value)}
            value={fakeUserFirstName}
          />
          <label htmlFor="firstName">Nombre</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setFakeUserFirstName("");
            refetchFakeUsers();
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className="p-card gap-4 p-3">
        <div className="flex w-full justify-content-center align-items-center gap-3 mt-4 mb-6">
          <h1 className="text-center">Usuarios</h1>
        </div>
        <DataTable
          value={usersData}
          loading={isLoading}
          header={RealUsersheader}
          tableStyle={{ minWidth: "60rem" }}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          <Column field="email" header="Email"></Column>
          <Column field="lastName" header="Apellido"></Column>
          <Column field="firstName" header="Nombre"></Column>
          <Column
            field="userPhone"
            header="Telefono"
            body={UserPhoneBodyTemplate}
          ></Column>
          <Column
            field="createDate"
            header="Fecha de creación"
            body={DateBodyTemplate}
          ></Column>
        </DataTable>
      </div>
      <div className="p-card gap-4 mt-5 p-3">
        <div className="flex w-full justify-content-center align-items-center gap-3 mt-4 mb-6">
          <h1 className="text-center">Usuarios ficticios</h1>
          <Button
            icon="pi pi-plus"
            rounded
            aria-label="Plus"
            size="small"
            onClick={() => navigate("new")}
          />
        </div>
        <DataTable
          value={fakeUsersData}
          loading={isLoadingFakeUsers || isLoadingDelete}
          header={fakeUsersHeader}
          tableStyle={{ minWidth: "60rem" }}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          <Column field="email" header="Email"></Column>
          <Column field="lastName" header="Apellido"></Column>
          <Column field="firstName" header="Nombre"></Column>
          <Column
            body={(rowData) => (
              <ActionButtonTemplate
                rowData={rowData}
                onDelete={setUserToDelete}
              />
            )}
          ></Column>
        </DataTable>
      </div>
      {userToDelete && (
        <Dialog
          header="Eliminar usuario ficticio"
          visible={!!userToDelete}
          style={{ width: "50vw" }}
          onHide={hideDeleteEventDialog}
          footer={
            <div>
              <Button
                severity="danger"
                label="Eliminar"
                onClick={() => handleDeleteUser(userToDelete.id)}
              />
              <Button
                severity="secondary"
                label="Cancelar"
                onClick={hideDeleteEventDialog}
              />
            </div>
          }
        >
          <h1 className="text-3xl text-red-500 m-0 p-0">
            <i className="pi pi-exclamation-triangle"></i> ¡Cuidado!
          </h1>
          <p className="m-0 p-0">
            ¿Estas seguro que desea eliminar el usuario{" "}
            <b>
              {userToDelete?.firstName} {userToDelete?.lastName}
            </b>
            ?
          </p>
        </Dialog>
      )}
    </>
  );
};
